import { useEffect } from 'react'
import dynamic from 'next/dynamic'
import AOS from 'aos'
import { Provider } from 'react-redux'
import { Toaster } from 'react-hot-toast'
import TagManager from 'react-gtm-module'
import secureLocalStorage from 'react-secure-storage'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import Helpers from '@/helpers'
import { cn } from '@/lib/utils'
import { fontClass } from '@/lib/font'
import store from '@/store/configureStore'
import FallbackSpinner from '@/custom-components/spinners/FallbackSpinner'
import { SettingsConsumer, SettingsProvider } from '@/@core/context/settingsContext'

import '~/styles/scss/app/app.scss'
import DetectResponsive from '@/components/detect-responsive/DetectResponsive'

const Layout = dynamic(() => import('@/components/layout/Layout'), {
  ssr: true,
  loading: () => <FallbackSpinner />
})

const ThemeComponent = dynamic(() => import('@/@core/theme/ThemeComponent'), {
  ssr: true
})

const ReactHotToast = dynamic(() => import('@/@core/styles/libs/react-hot-toast'), {
  ssr: false
})

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
})

const packageVersion = require('../package.json').version

const gtmId = process.env.NEXT_PUBLIC_GOOGLE_TAG_ID || ''

const tagManagerArgs = { gtmId }

const PayToME = ({ Component, pageProps }) => {
  const setConfig = Component.setConfig ?? undefined
  const getLayout = Component?.getLayout ?? (page => <Layout>{page}</Layout>)

  const storedVersion = typeof window !== 'undefined' ? localStorage.getItem('_av') : false

  useEffect(() => {
    if (typeof window !== 'undefined' && storedVersion !== packageVersion) {
      localStorage.clear()
      secureLocalStorage.clear()
      Helpers.removeCookies(['_cp'])
      localStorage.setItem('_av', packageVersion)
      window.location.reload()
    }
  }, [storedVersion])

  useEffect(() => {
    TagManager.initialize(tagManagerArgs)
  }, [])

  // Animation support
  useEffect(() => {
    AOS.init({
      once: false,
      duration: 1000,
      easing: 'ease-out-back',
      startEvent: 'DOMContentLoaded'
    })
  }, [])

  return (
    <>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <SettingsProvider {...(setConfig ? { pageSettings: setConfig() } : {})}>
            <SettingsConsumer>
              {({ settings }) => (
                <ThemeComponent settings={settings}>
                  <div className={cn('ptm-user-side-wr', fontClass)}>
                    {getLayout(<Component {...pageProps} />)}
                    <ReactHotToast>
                      <Toaster position={settings.toastPosition} toastOptions={{ className: 'react-hot-toast' }} />
                    </ReactHotToast>
                  </div>
                  <DetectResponsive />
                </ThemeComponent>
              )}
            </SettingsConsumer>
          </SettingsProvider>
        </QueryClientProvider>
      </Provider>
    </>
  )
}

export default PayToME
